<template>
  <div
    ref="wrapper"
    v-scroll="handleScroll"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <v-progress-linear
        color="shamrock accent-4"
        indeterminate
        rounded
        height="6"
      />
      <div class="title">
        ...Loading Version
      </div>
    </div>
    <div v-else>
      <v-img
        v-if="$vuetify.breakpoint.xsOnly"
        ref="parallax"
        :src="project && project.image && project.image.content ? project.image.content : null"
        style="height: 28.4vmax; min-height: 300px; max-height: 60vh; color: white;"
        class="d-flex align-center justify-center"
      >
        <ProjectStats
          v-if="version"
          :version-id="version._id"
          compact
        />
        <a
          class="calculator-scroll-prompt"
          @click="scrollToInputs"
        >
          <v-btn
            icon
            color="leaf"
            x-large
          >
            <v-icon x-large>
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </a>
      </v-img>
      <v-parallax
        v-else
        ref="parallax"
        v-resize="updateImageHeight"
        dark
        :src="project ? project.image.content : null"
        style="height: 28.4vmax; min-height: 300px; max-height: 60vh; object-fit: cover;"
      >
        <ProjectStats
          v-if="version"
          :version-id="version._id"
          compact
        />
        <a
          class="calculator-scroll-prompt"
          @click="scrollToInputs"
        >
          <v-btn
            icon
            color="leaf"
            x-large
          >
            <v-icon x-large>
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </a>
      </v-parallax>
      <div
        ref="tab-bar"
        style="height: 56px; position: relative; z-index: 5;"
      >
        <v-toolbar
          ref="tab-bar-child"
          :height="50"
          flat
          style="left: 0; right: 0;"
        >
          <v-tabs
            v-model="activeTab"
            background-color="onyx"
            dark
            centered
            :grow="$vuetify.breakpoint.xsOnly"
          >
            <v-tab
              v-for="type in types"
              :key="type"
            >
              {{ type }}s
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </div>
      <!-- <div class="white d-flex justify-center" style="height: 60px;">
        8.574 tonnes
      </div> -->
      <v-container
        v-if="project && version"
        :style="{
          'min-height': stickyStats ? '100vh' : 'auto',
          'padding-bottom': $vuetify.breakpoint.xsOnly ? '40px' : '70px'
        }"
      >
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="10"
            lg="5"
            xl="4"
            :class="{ 'py-0': $vuetify.breakpoint.xsOnly }"
          >
            <div
              :style="{ position: stickyStats ? 'fixed' : 'static', width: statsWidth }"
              style="top: 75px; width: 570px;"
            >
              <CalculatorLeftPanel
                :adding-elements="addingElements"
                :type="activeType"
                :event-key="eventKey"
                @set-subtype="setSubtype"
                @add-elements="addElements"
                @update-element="updateElement"
                @delete-element="deleteElement"
                @play-intro="playIntro"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="10"
            lg="7"
            xl="6"
          >
            <div class="calculator-inputs">
              <v-skeleton-loader
                v-if="!version"
                type="list-item-two-line@5"
                class="py-4"
              />

              <v-tabs-items
                v-model="activeTab"
                class="transparent"
              >
                <v-tab-item
                  v-for="(type, index) in types"
                  :key="type"
                >
                  <BenefitsCalculatorTab
                    v-if="type === 'benefit'"
                    :type="type"
                    :subtype="subtype"
                    :is-active="activeTab === index"
                    :event-key="eventKey"
                    @updated="handleCalculatorTabUpdated"
                    @updating="handleCalculatorTabUpdating"
                  />
                  <CalculatorTab
                    v-else
                    :type="type"
                    :subtype="subtype"
                    :is-active="activeTab === index"
                    :event-key="eventKey"
                    @updated="handleCalculatorTabUpdated"
                    @updating="handleCalculatorTabUpdating"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="showSuggestions" />
      <div
        class="bottom-sheet elevation-3"
        :class="{ 'show': showSuggestions, 'bounce': bounceSuggestions, 'empty': designSuggestionCategories.length === 0 }"
        @click="!showSuggestions ? showSuggestions = true : null"
        @wheel="!showSuggestions ? showSuggestions = true : null"
      >
        <div
          class="activator"
          @click.stop="showSuggestions = !showSuggestions"
        >
          <span
            v-if="true"
            class="open button d-flex align-center"
            :class="$vuetify.breakpoint.xsOnly ? 'subtitle-2' : 'subtitle-1'"
          >
            Design suggestions
            <v-icon
              right
              style="margin-bottom: 3px;"
              :small="$vuetify.breakpoint.xsOnly"
            >
              {{ showSuggestions ? 'mdi-lightbulb-on' : 'mdi-lightbulb-on-outline' }}
            </v-icon>
          </span>
          <span
            v-else
            class="close button subtitle-1 d-flex align-center mr-1"
            transition="v-fade-in"
            text
          >
            <v-icon left>mdi-chevron-down</v-icon>
            Close
          </span>
        </div>
        <v-sheet
          v-on-clickaway="() => showSuggestions = false"
          class="bottom-sheet-content"
          width="100%"
          style="overflow: auto;"
        >
          <DesignSuggestions
            :categories="designSuggestionCategories"
            :loading="loadingDesignSuggestionCategories || statsLoading || formDisabled"
            :visible="showSuggestions"
          />
        </v-sheet>
      </div>
      <div
        v-if="activeType !== 'benefit'"
        class="ytp-donut-wrapper"
        :class="{ 'show-target': showYtpTarget }"
      >
        <YearsToPositiveDonut
          v-if="project && version"
          v-show="!showSuggestions"
          :pending="version.stats.pending"
          :years-to-positive="version.stats.yearsToPositive"
          :project-type="project.type"
          text-inside
          fill="var(--v-leaf-base)"
        />
        <div
          class="target white mt-2 px-3 py-1 text-center shamrock--text"
          style="border-radius: 3px;"
        >
          <span
            v-if="version && version.stats.yearsToPositive && version.stats.yearsToPositive < targetYtp"
            style="font-family: 'Roboto', sans-serif;"
          >
            Challenge target is <strong style="font-weight: 500;">{{ targetYtp }} years</strong><br>
            <span>Looking good!</span>
          </span>
          <span v-else>
            Offset impact in <strong style="font-weight: 500;">{{ targetYtp }} years</strong><br> to meet the Challenge
          </span>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showRelatedElementsModal"
      width="440"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Related Elements
        </v-card-title>
        <v-card-text>
          Select the related elements to be added
        </v-card-text>
        <v-divider />
        <v-list shaped>
          <v-list-item-group
            v-model="selectedRelElements"
            multiple
          >
            <template v-for="(item, i) in relElements">
              <v-divider
                v-if="!item"
                :key="`divider-${i}`"
              />
              <v-list-item
                v-else
                :key="`item-${i}`"
                :value="item"
                active-class="shamrock--text text--accent-4"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="shamrock accent-4"
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="() => { showRelatedElementsModal = false; selectedRelElements.length > 0 && addElements(selectedRelElements, true) }"
          >
            Add to elements list
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CalculatorLeftPanel from './calculatorLeftPanel/CalculatorLeftPanel'
import CalculatorTab from './calculatorTab/CalculatorTab'
import ProjectStats from '@/components/application/projectStats/ProjectStats'
import YearsToPositiveDonut from '@/components/atoms/YearsToPositiveDonut'
import DesignSuggestions from './designSuggestions/DesignSuggestions'
import { mixin as clickaway } from 'vue-clickaway2'
import { captureException } from '@sentry/vue'

import { createNamespacedHelpers, mapGetters as mapGlobalGetters, mapMutations as mapGlobalMutations, mapActions as mapGlobalActions } from 'vuex'
import BenefitsCalculatorTab from './calculatorTab/benefits/BenefitsCalculatorTab'
import { allTargetConditions } from '@/views/admin/percentNative/constants'
const { mapGetters: mapProjectGetters, mapActions: mapProjectActions } = createNamespacedHelpers('projects')
const { mapActions: mapListActions } = createNamespacedHelpers('lists')
const { mapGetters: mapVersionGetters, mapMutations: mapVersionMutations, mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')
const V2_TAB_TYPES = ['material', 'plant', 'operation']
const V3_TAB_TYPES = ['benefit']

const typeToTab = (type) => {
  if (type === 'hydrozone') {
    return 'operation'
  }
  if (type === 'maintenance') {
    return 'operation'
  }
  return type
}

export default {
  name: 'ProjectCalculator',
  components: {
    BenefitsCalculatorTab,
    CalculatorLeftPanel,
    CalculatorTab,
    ProjectStats,
    DesignSuggestions,
    YearsToPositiveDonut
  },
  mixins: [ clickaway ],
  props: {
    projectId: {
      type: String,
      default: null
    },
    versionId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      activeTab: 0,
      subtype: null,
      showSuggestions: false,
      bounceSuggestions: false,
      designSuggestionCategories: [],
      loading: true,
      loadingDesignSuggestionCategories: true,
      eventKey: null,
      stickyTabs: false,
      stickyStats: false,
      addingElements: false,
      statsExpanded: true,
      imageHeight: 0,
      introHasStarted: false,
      showYtpTarget: false,
      numberOfElements: null,
      showRelatedElementsModal: false,
      relElements: [],
      selectedRelElements: []
    }
  },
  computed: {
    ...mapProjectGetters(['project']),
    ...mapVersionGetters(['version', 'statsLoading', 'formDisabled']),
    ...mapGlobalGetters(['systemOfMeasurement', 'projectTypes', 'cpdConfiguration']),
    types () {
      return [...V2_TAB_TYPES, ...V3_TAB_TYPES]
    },
    activeType () {
      return this.types[this.activeTab]
    },
    targetYtp () {
      if (!this.project) {
        return 5
      }
      const { targetYears } = this.projectTypes.find(x => x.name === this.project.type)
      return targetYears
    },
    statsWidth () {
      const { name: size } = this.$vuetify.breakpoint
      switch (size) {
        case 'xl':
          return '570px'
        case 'lg':
          return '470px'
        default:
          return 'auto'
      }
    }
  },
  watch: {
    statsLoading (val) {
      if (!val) {
        setTimeout(() => {
          this.showYtpTarget = true
          setTimeout(() => {
            this.showYtpTarget = false
          }, 5000)
        }, 100)
      }
    }
  },
  beforeDestroy () {
    this.$io.socket('/').off('stats')
  },
  async created () {
    this.loading = true
    this.setFormDisabled(true)
    this.$io.socket('/').on('stats', (payload) => {
      const { stats, attributes, hasShrubs } = payload
      this.setVersionStats({ stats, attributes })
      this.setHasShrubs(hasShrubs)
      this.setStatsLoading(false)
      this.getDesignSuggestions()
    })
    if (!this.projectId) {
      return this.$router.push('/projects')
    }
    // Get the associated lists from the server
    await this.fetchLists()

    if (!this.versionId) {
      this.showSnackbar({ color: 'error', text: 'Whoops, no project version specified, redirecting. Please click a version to edit.' })
      captureException(`Project ${this.projectId} edit page visited with no version param`)
      return this.$router.push(`/projects/${this.projectId}`)
    }
    this.setVersion(null)
    if (!this.project || this.project._id !== this.projectId) {
      await this.fetchProjectById(this.projectId)
    }
    if (!this.versionId) {
      captureException(new Error('No version id available to fetch'))
    }
    await this.fetchVersion({ id: this.versionId })
    this.setPage({ title: `${this.project.title}<span class="body-2 px-2">${this.version.title}</span>` })
    this.getDesignSuggestions()
    this.setFormDisabled(false)
    this.loading = false
  },
  async mounted () {
    this.handleScroll()
    this.updateImageHeight()
  },
  methods: {
    ...mapGlobalMutations(['setPage']),
    ...mapGlobalActions(['showSnackbar']),
    ...mapProjectActions(['fetchProjectById']),
    ...mapListActions(['fetchLists']),
    ...mapVersionActions(['fetchVersion']),
    ...mapVersionMutations(['setVersion', 'setVersionStats', 'setHasShrubs', 'setStatsLoading', 'setFormDisabled']),
    updateImageHeight () {
      if (this.$refs?.parallax?.$el?.clientHeight) {
        this.imageHeight = this.$refs.parallax.$el.clientHeight
      }
    },
    scrollToInputs () {
      window.scrollTo({
        left: 0,
        top: this.imageHeight + 70,
        behavior: 'smooth'
      })
    },
    handleScroll () {
      const el = this.$refs['tab-bar']
      if (el) {
        const { top } = el.getBoundingClientRect()
        const child = this.$refs['tab-bar-child'].$el

        const sticky = top <= 64
        this.stickyTabs = sticky
        this.stickyStats = top < -5 && this.$vuetify.breakpoint.lgAndUp
        // transition the tab away
        if (!sticky) {
          child.style.top = 0
          window.document.getElementById('main-app-bar').style.transform = 'none'
        } else {
          child.style.top = `${Math.max(top, 0)}px`
          window.document.getElementById('main-app-bar').style.transform = `translateY(${top - 64}px)`
        }
        child.style.position = sticky ? 'fixed' : 'absolute'
      }
    },
    async playIntro () {
      if (this.cpdConfiguration?.show_calculator_intro_js) {
        if (!this.numberOfElements) {
          // if number of elements null (unknown) or zero, fetch first
          const { data: numberOfElements } = await this.$axios.get(`/versions/${this.version._id}/elements_count`)
          this.numberOfElements = numberOfElements
        }
        if (this.numberOfElements > 0) {
          // if version already has elements don't show intro
          return
        }
        this.$intro()
          .addStep({ intro: 'test' })
        this.$intro()
          .setOptions({
            showStepNumbers: false,
            overlayOpacity: 0.4,
            hidePrev: true,
            hideNext: true,
            doneLabel: 'Let me try',
            skipLabel: 'Skip guide',
            disableInteraction: true
          })
          .start()
        this.introHasStarted = true
      }
    },
    setSubtype (subtype) {
      this.subtype = subtype
    },
    async updateElement (element, related = false, callback) {
      const elementCopy = JSON.parse(JSON.stringify(element))
      this.addingElements = true
      this.setFormDisabled(true)

      await this.$axios.put(`projects/${this.projectId}/customTemplate`, { updates: elementCopy })

      const iframe = document.getElementById('iframeid')
      if (iframe) {
        iframe.src += ''
      }
      this.addingElements = false
      // this will reload the calculator
      // TODO: refactor this
      this.eventKey = Math.random()
      callback && callback()
      this.setFormDisabled(false)
    },
    async addElements (elements, related = false, callback) {
      // elements that can be added
      // 1: results of CustomForm
      // 2: customTemplates
      // 2: regular templates
      elements = JSON.parse(JSON.stringify(elements))
      this.addingElements = true
      this.setFormDisabled(true)
      if (!Array.isArray(elements)) {
        // if custom element
        elements.totalQantity = 0
        elements.emissionsCo2Kg = 0
        elements.totalCo2Kg = 0
        elements.layouts[0].dimensions[0].value = 0
        const elementCopy = JSON.parse(JSON.stringify(elements))

        // results of CustomForm
        // isCustomTemplate is not defined && isTemplate is not defined then it's a custom form
        if (!elementCopy.isCustomTemplate && !elementCopy.isTemplate) {
          const customTemplate = (await this.$axios.post(`projects/${this.projectId}/customTemplate`, { elements: elementCopy })).data[0]
          customTemplate.template = customTemplate._id
          // don't set a cpdVersion for custom templates.
          // customTemplate.cpdVersion = this.version.cpdVersions?.includes(3) ? 3 : 2
          delete customTemplate.isCustomTemplate
          delete customTemplate.project
          elements = [customTemplate]
        } else {
          delete elementCopy.project
          delete elementCopy.isCustomTemplate
          delete elementCopy.isTemplate
          elementCopy.template = elementCopy._id
          elements = [elementCopy]
        }
      }
      elements = elements.map(element => {
        delete element._id
        delete element.__v

        if (!element.project) {
          element.version = this.versionId
        }
        if (element.isTemplate) {
          element.template = element._id
        }

        // set defaults for target condition
        if (element.showTargetCondition) {
          element.targetCondition = allTargetConditions?.[0]?._id
        }

        // set defaults for transportation and transportationDistance
        if (element.defaultMaterialOption) {
          element.selectedMaterial = element.defaultMaterialOption
        }
        // set defaults for nursery
        if (element.defaultNurseryOption) {
          element.selectedNursery = element.defaultNurseryOption
        }
        // set defaults for transportation and transportationDistance
        if (element.defaultTransportationOption) {
          element.selectedTransportationOption = element.defaultTransportationOption
        }
        if (element.defaultTransportationDistanceOption) {
          element.selectedTransportationDistanceOption = element.defaultTransportationDistanceOption
        }
        // set defaults for Structural Diversities
        if (element.defaultStructuralDiversities) {
          element.selectedStructuralDiversities = element.defaultStructuralDiversities
        }
        // set defaults for Irrigation
        if (element.defaultIrrigationTypeOption) {
          element.selectedIrrigationType = element.defaultIrrigationTypeOption
        }
        // set defaults for PlantingType
        if (element.defaultPlantingTypeOption) {
          element.selectedPlantingType = element.defaultPlantingTypeOption
        }
        // set defaults for Percent Native
        if (element.defaultPercentNative) {
          element.percentNative = element.defaultPercentNative
        }
        element.isTemplate = false
        return element
      })
      await this.$axios.post(`versions/${this.versionId}/elements`, { elements })
      // user feedback
      if (elements.length === 1) {
        if (related) {
          this.relElements = elements[0].relatedElements
          if (this.relElements && this.relElements.length) this.showRelatedElementsModal = true
        } else {
          this.relElements = []
          this.selectedRelElements = []
        }
        const displayed = this.typeToDisplay(elements[0].type)
        await this.showSnackbar(`Created new ${displayed}, ${elements[0].name}.`)
      } else {
        if (!this.relElements) this.showSnackbar('Successfully added shrubs')
      }
      this.activeTab = this.types.indexOf(typeToTab(elements[0].type))
      this.eventKey = Math.random()
      const iframe = document.getElementById('iframeid')
      if (iframe) {
        iframe.src += ''
      }
      this.addingElements = false
      callback && callback()
      this.setFormDisabled(false)
    },
    typeToDisplay (type) {
      return type === 'maintenance' ? 'operation' : type
    },
    async deleteElement (element, callback) {
      this.setFormDisabled(true)
      if (!element._id) {
        this.showSnackbar({ color: 'error', text: 'Unable to delete element. Please refresh the page and try again.' })
      }
      try {
        this.delete = true
        await this.$axios.delete(`/elements/${element._id}`)
        this.showSnackbar(`Successfully deleted element "${element.name}"`)
        this.$emit('deleted')
        this.delete = false
        callback && callback()
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Unable to delete element. Please refresh the page and try again.' })
        this.deleting = false
        throw err
      }
      this.setFormDisabled(false)
    },
    async handleCalculatorTabUpdating () {
      this.setFormDisabled(true)
    },
    async handleCalculatorTabUpdated () {
      this.handleScroll()
      this.$nextTick(async () => {
        this.setStatsLoading(true)
        const iframe = document.getElementById('iframeid')
        if (iframe) {
          iframe.src += ''
        }
        setTimeout(async () => {
          await this.fetchVersion({ id: this.versionId })
          this.setStatsLoading(false)
          this.setFormDisabled(false)
        }, 1000)
      })
    },
    async getDesignSuggestions () {
      this.loadingDesignSuggestionCategories = true
      try {
        let { data: categories } = await this.$axios.get(`versions/${this.versionId}/suggestions`)
        this.designSuggestionCategories = categories
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Error loading latest design suggestions. Please get in touch if the issue persists.' })
        throw err
      }
      this.loadingDesignSuggestionCategories = false
    }
  }
}
</script>

<style lang="scss">
.v-skeleton-loader__list-item-two-line {
  margin: 15px 0;
}
.bottom-sheet {
  position: fixed;
  left: 0;
  width: 100%;
  @media only screen and (min-width: 600px) {
    width: 86vw;
    left: 7vw;
  }
  bottom: -6.4em;
  display: flex;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  transition: height 0.3s;
  &.bounce {
    @keyframes bounce {
      0% {
        height: 30px;
      }
      50% {
        height: 40px;
      }
      100% {
        height: 30px
      }
    }
    animation: 0.7s ease-in-out 1s infinite bounce;
  }
  &.show {
    cursor: default;
    height: 90vh;
    &.empty {
      height: 400px;
    }
    background-color: none;
  }
  .activator {
    position: absolute;
    bottom: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
    height: 25px;
    @media only screen and (min-width: 600px) {
      height: 40px;
    }
    width: 230px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}
.ytp-donut-wrapper {
  position: fixed;
  right: 7px;
  bottom: 20px;
  @media only screen and (min-width: 600px) {
    right: 10vmin;
    bottom: 10vmin;
  }
  z-index: 100;
  perspective: 1000px;
  .target {
    white-space: nowrap;
    position: absolute;
    right: calc(100% + 15px);
    top: 50%;
    transform: rotateX(60deg) translateY(-50%) translateY(-10px);
    @media only screen and (min-width: 600px) {
      right: 50%;
      top: 100%;
      transform: translateX(50%) rotateX(60deg) translateY(10px);
    }
    opacity: 0;
    transition: all 0.2s;
    transform-origin: bottom;
    span {
      transition: all 0.4s;
      display: block;
      opacity: 0;
    }
  }
  &.show-target .target, &:hover .target {
    opacity: 1;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.15);
    transform: translateY(-50%) rotateX(0);
    @media only screen and (min-width: 600px) {
      transform: translateX(50%) rotateX(0);
    }
    span {
      opacity: 1;
    }
  }
}
.calculator-scroll-prompt {
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(13px) scale(1.3);
    }
    // 90% {
    //   transform: translateY(0);
    // }
    100% {
      transform: translateY(0);
    }
  }
  button {
    animation: bounce 2s infinite;
  }
}
</style>
